.logo-beta {
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #bc1010;
	color: white;
	height: 24px;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
}

.install-app {
	cursor: pointer;
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #1565c0;
	text-decoration: underline;
	height: 40px;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
}