.feedback-form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
}

.feedback-form > p,
.feedback-form > div,
.feedback-form > label {
	margin-bottom: 15px;
}

.feedback-input > div {
	height: 184px;
}

.feedback-input textarea {
	margin-top: 24px;
}

.it-toolbar {
	display: grid;
	grid-template-rows: 60px;
	grid-template-columns: 100px 1fr 100px;
}