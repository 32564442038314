ion-modal.profile {
	--border-radius: 16px;
	--width: 100%;

	#ion-react-wrapper {
		flex-direction: unset !important;
	}
}

.field-item {
	padding: 15px 26px 15px 26px;
	display: grid;
	grid-template-columns: 50px 1fr 100px;
	grid-template-areas: 'icon label edit';
}

.field-item .profile-field-label {
	grid-area: label;
	display: flex;
	flex-direction: column;
}

.field-item .profile-field-edit {
	grid-area: edit;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.field-item .profile-field-icon {
	grid-area: icon;
}

.field-form {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.field-form > div {
	margin-bottom: 20px;
}