.spray-instruction {
	padding: 10px 10px 20px 10px;
}

.spray-input-form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	/*gap: 30px;*/
	padding: 10px;
}

.spray-input-form > div {
	padding-bottom: 30px;
}

.spray-textarea > div {
	height: 190px; /*Fix for iOS only. In other browsers, this is overidden*/
}