.page-header {
	background-color: white;
}

.page-header::after {
	background-image: unset;
}

.page-header-container {
	width: 100%;
	display: grid;
	grid-template-rows: var(--page-header-height, 56px);
	grid-template-columns: auto 1fr auto;
	box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
}

.page-header-item {
	display: flex;
	align-items: center;
}

.page-header-title {
	display: flex;
	flex-direction: column;
}

.page-header-title .title-item {
	padding: 0px 20px 0px 20px;
	white-space: nowrap;
}

.page-header-title .subtitle {
	font-size: 12px;
}

.page-header-left {
	justify-content: flex-start;
	padding-left: 15px;
}

.page-header-middle-center {
	justify-content: center;
}

.page-header-middle-left {
	justify-content: flex-start;
}

.page-header-right {
	justify-content: flex-end;
	padding-right: 15px;
}