.weather-header {
	box-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
}

.weather-header::after {
	background-image: unset;
}

.gradient-bg {
	background: var(--mu-primary-dark);  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.linear-bg {
	background: var(--mu-primary-main);  /* fallback for old browsers */
}

.toolbar-header {
	padding: var(--padding);
	transition: padding 200ms ease-out;
}

.search-container {
	width: 100vw;
	height: calc(100vh - 56px);
	background-color: white;
}

.content-container {
	padding: 15px 15px 15px 15px;
	transform: scale(1) translate(0px, 0px);
	transform-origin: top left;
}

.app-bar {
	--tab-height: 40px;
	margin-top: -10px;
	box-shadow: unset !important;
}

.app-bar button {
	font-size: 16px;
	margin-top: -3px;
}

.custom-tabs {
	min-height: var(--tab-height, 40px) !important;
	height: var(--tab-height, 40px) !important;
}

.custom-tab {
	text-transform: none !important;
	min-height: var(--tab-height, 40px) !important;
	height: var(--tab-height, 40px) !important;
}

.line-item {
	display: grid;
	grid-template-rows: repeat(2,23px) auto;
	grid-template-columns: 1fr 130px;
	grid-template-areas:
		'tower     map'
		'distance  map'
		'default   map';
	cursor: pointer;
	padding: 12px 26px 12px 26px;
}

.line-item:hover {
	background-color: #f4f4f4;
}

.line-item .tower-line {
	grid-area: tower;
	font-weight: 500;
}

.line-item .distance-line {
	grid-area: distance;
}

.line-item .default-line {
	grid-area: default;
	margin-top: 4px;
}

.line-item .map-line {
	grid-area: map;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.line-item .MuiChip-sizeSmall {
	transform: scale(0.9);
}

.field-def {
	padding: 5px 26px;
}

.field-def .close-header {
	display: flex;
	justify-content: flex-end;
}

.field-def .field-description {
	margin-top: 20px;
}

.content-section {
	width: 100%;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 450px 1fr;
	grid-template-areas:'left   data   right';
}

.content-section .side-area {
	margin: 66px 20px 20px 20px;
	box-sizing: border-box;
}

.content-section .side-area.left-area {
	grid-area: left;
}

.content-section .side-area.right-area {
	grid-area: right;
}

.content-section .data-area {
	grid-area: data;
}

@media (max-width: 768px) {
	.left-area {
		display: none;
	}
	.content-section {
		grid-template-rows: auto 100px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'data'
			'right';
	}
	
	.content-section .side-area {
		margin: 40px 0px 40px 0px;
		padding-bottom: 80px;
	}
	
	.ad-box {
		width: 428px;
	}
	
	.content-section .side-area.right-area {
		display: flex;
		justify-content: center;
		height: fit-content;
	}
}